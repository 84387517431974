import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CiCalendarDate } from "react-icons/ci";
import { MdGpsFixed } from "react-icons/md";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { getImageUrl } from "../../index.js";
import { GetEvenement, Evnt_OG } from "../../Redux/Slice/EvenementSlice";
import logo from "../../images/logo.jpg";
import "./css/evenement.css";
import { Helmet } from "react-helmet-async";

const EvenementPartage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [ogMetaData, setOgMetaData] = useState({});
  const { Evenement } = useSelector((state) => state.evenement);
  const { html } = useSelector((state) => state.og);

  useEffect(() => {
    dispatch(GetEvenement(id));
  }, [dispatch, id]);

  const loadedEvent = Evenement.find((evt) => evt._id === id);
console.log(loadedEvent)
  useEffect(() => {
      dispatch(Evnt_OG(loadedEvent._id));
    console.log(html)
  }, [loadedEvent, dispatch]);
  
  useEffect(() => {
    if (html) {
      setOgMetaData(html);
    }
    console.log(ogMetaData)

  }, [html]);
  
  useEffect(() => {
    if (loadedEvent && html) {
      const parser = new DOMParser();
      const document = parser.parseFromString(html, "text/html");
  
      const titleMeta = document.querySelector('meta[property="og:title"]');
      const urlMeta = document.querySelector('meta[property="og:url"]');
      const imageMeta = document.querySelector('meta[property="og:image"]');

      if (titleMeta && urlMeta) {
        setOgMetaData({
          title: titleMeta.getAttribute("content"),
          url: urlMeta.getAttribute("content"),
          image: imageMeta.getAttribute("content"),

        });
      }
    }
  }, [html, loadedEvent]);
  

  const ogTitle = ogMetaData.title || ogMetaData?.titre || "Evenement";
const share_Url = ogMetaData.url
  ? ogMetaData.url
  : "https://moncoach.tn";
  const share_image = ogMetaData.image
  ? ogMetaData.image
  : "https://moncoach.tn/logo.jpg";
console.log(share_image)
console.log(share_Url)
console.log(ogTitle)

  return (
    <>
    <Helmet>
  <title>{ogTitle}</title>
  <meta name="title" content={ogTitle} />
  <meta property="og:title" content={ogTitle} />
  <meta property="og:image" content={share_image} />
  <meta property="og:url" content={share_Url} />
</Helmet>

      <div style={{ padding: "40px" }}>
        <img src={logo} alt="logo" width="220px" height="70" />
        <hr />
        <div>
          <img
            src={getImageUrl(loadedEvent.photo)}
            alt="Event"
            className="Evnt-image"
          />
        </div>
        <div className="modal-Evnt">
          <h2 className="Evnt-titre">{loadedEvent.titre}</h2>
          <div
            className="Evnt-descri"
            dangerouslySetInnerHTML={{ __html: loadedEvent.texte }}
          />
          <div className="Evnt-info">
            <div className="info-item">
              <CiCalendarDate className="info-icon" />
              <h5 className="info-date">{loadedEvent.dates}</h5>
            </div>
            <div className="info-item">
              <MdGpsFixed className="info-icon" />
              <h5 className="info-lieu">{loadedEvent.lieu}</h5>
            </div>
          </div>
          <div className="partagerEVNT">
            <div>
              <FacebookShareButton url={share_Url} quote={ogTitle}>
                <div className="share-button">
                  <FacebookIcon style={{ color: "#fff" }} size={20} />
                  <h3 className="info-item">Partage</h3>
                </div>
              </FacebookShareButton>
            </div>
            <div>
              <LinkedinShareButton url={share_Url}>
                <div className="share-button">
                  <LinkedInIcon style={{ color: "#fff" }} size={20} />
                  <h3 className="info-item">Partage</h3>
                </div>
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EvenementPartage;
