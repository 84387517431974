import React, { useEffect, useState, useRef } from "react";
import image from "../../images/big_image_2.jpg";
import logo from "../../images/logo.jpg";
import {
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { MdPerson } from "react-icons/md";
import "./css/ajouterarticle.css";
import { useDispatch, useSelector } from "react-redux";
import { AddArticle, Artc_OG, GetArticle } from "../../Redux/Slice/ArticleSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BASE_URL_back, getImageUrl } from "../..";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "@mui/material";

const AjoutArticle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Articles } = useSelector((state) => state.article);
  const [imageArticle, setImageArticle] = useState(null);
  const [errors, setErrors] = useState({});
  const authorRef = useRef(null);
  const titleRef = useRef(null);
  const linkRef = useRef(null);
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(null);
  const [editorData, setEditorData] = useState('');
  const [randomArtc, setrandomArtc] = useState('');

  useEffect(() => {
    dispatch(GetArticle());
    const randomArtc = Articles
    .slice()
    .sort(() => Math.random() - 0.5)
    .slice(0, 4);
    setrandomArtc(randomArtc)
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const elem = document.querySelector('.section-hero');
      const { top } = elem.getBoundingClientRect();
      if (elem) {
        const backgroundPositionY = -(top + 25) * 0.6;
        elem.style.backgroundPosition = `50% ${backgroundPositionY}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

 
  const validateForm = () => {
    const newErrors = {};
    if (!authorRef.current.value) {
      newErrors.authorRef = "Veuillez renseigner ce champ.";
    }
    if (!titleRef.current.value) {
      newErrors.titleRef = "Veuillez renseigner ce champ.";
    }
    if (!editorData) {
      newErrors.editorRef = "Veuillez renseigner ce champ.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImageChange = (e) => {
    setImageArticle(e.target.files[0]);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    formData.append("auteur", authorRef.current.value);
    formData.append("titre", titleRef.current.value);
    formData.append("texte", editorData);
    formData.append("imagee", imageArticle);
    formData.append("lien", linkRef.current.value);

    dispatch(AddArticle(formData));
    navigate("/EspaceCoach", { replace: true });
    window.scrollTo(0, 0);
  };

  const handleClickOpen = (article) => {
    setCurrentArticle(article);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentArticle(null);
  };

  useEffect(() => {
    if (currentArticle) {
      dispatch(Artc_OG(currentArticle._id));
    }
  }, [currentArticle, dispatch]);

  const shareURL = currentArticle ? `${BASE_URL_back}/articles/${currentArticle._id}` : '';

  return (
    <>
      <div
        className="PlatformeArticle-Add section-hero"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div>
          <h3 className="ArtcTitre">Partagez un article, une offre</h3>
        </div>
      </div>

      <div className="ajouterArtc section-hero">
        <div className="ajouterArtcContainer">
          <div className="left-Article">
            <h3 className="left-ArticlePartager">Articles, offres déjà partagé(e)s</h3>
            {randomArtc &&
              randomArtc.map((article, index) => (
                <div key={index} className="Article-Item">
                  <img
                    src={getImageUrl(article.photo)}
                    alt="Article"
                    className="left-Article-img"
                  />
                  <h1
                    className="titreART"
                    onClick={() => handleClickOpen(article)}
                  >
                    {article.titre}
                  </h1>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MdPerson className="artic-author-icon" />
                    <h2 className="auteurART">{article.auteur}</h2>
                  </div>
                </div>
              ))}
          </div>

          <div className="right-Article">
            <h3 className="FormulaireCoach">
              Pour partager un article, une offre, cet espace est pour vous !
            </h3>
            <form onSubmit={handleFormSubmit}>
              <label className="LabelArticle">Auteur(e)/ Entreprise:</label>
              <input
                type="text"
                placeholder=""
                ref={authorRef}
                className="inputArticle"
              />
              {errors.authorRef && <p className="error-message">{errors.authorRef}</p>}

              <label className="LabelArticle">Titre:</label>
              <input
                type="text"
                placeholder=""
                ref={titleRef}
                className="inputArticle"
              />
              {errors.titleRef && <p className="error-message">{errors.titleRef}</p>}

              <label className="LabelArticle">Texte:</label>
              <CKEditor
                editor={ClassicEditor}
                data=""
                onReady={(editor) => {
                  // editor is ready to use
                }}
                onChange={(event, editor) => {
                  setEditorData(editor.getData());
                }}
              />
              {errors.editorRef && <p className="error-message">{errors.editorRef}</p>}

              <label className="LabelArticle">Photo ou illustration:</label>
              <input
                type="file"
                placeholder=""
                onChange={handleImageChange}
                name="imagee"
                className="FileArticle"
                ref={fileInputRef}
              />

              <label className="LabelArticle">
                Lien (si votre texte est publié sur un site):
              </label>
              <input
                type="text"
                placeholder=""
                ref={linkRef}
                className="inputArticle"
              />

              <input className="BoutonArticle" type="submit" />
            </form>
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent style={{ padding: "20px" }}>
          {currentArticle && (
            <div className="ArticleContai">
              <img src={logo} alt="Article" style={{ width: "180px" }} />
              <hr />
              <img
                src={getImageUrl(currentArticle.photo)}
                alt="Article"
                style={{ display: "block", margin: "0 auto", width: "200px" }}
              />
              <h3 className="ART-titre">{currentArticle.titre}</h3>
              <div
                className="Art-Descrip"
                dangerouslySetInnerHTML={{ __html: currentArticle.texte }}
              />
              <div className="Artc-inf">
                <div className="Artc-author">
                  <div style={{ paddingLeft: "30px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <MdPerson className="artic-author-icon" />
                      <h5 className="articl-auteur">
                        {currentArticle.auteur}
                      </h5>
                    </div>
                    <div
                      className="partageArticle"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "20px",
                      }}
                    >
                      <div>
                        <FacebookShareButton
                          url={shareURL}
                          quote={currentArticle.titre}
                          hashtag="#evenement"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#0965FE",
                              paddingRight: "5px",
                            }}
                          >
                            <FacebookIcon style={{ color: "#fff" }} size={20} />
                            <h3 className="info-item">Partage</h3>
                          </div>
                        </FacebookShareButton>
                      </div>
                      <div>
                        <LinkedinShareButton url={shareURL}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#0077b5",
                              paddingRight: "5px",
                            }}
                          >
                            <LinkedInIcon style={{ color: "#fff" }} size={20} />
                            <h3 className="info-item">Partage</h3>
                          </div>
                        </LinkedinShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AjoutArticle;
