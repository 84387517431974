import React, { useState, useEffect } from "react";
import image from "../../images/big_image_2.jpg";
import ChercheCoach from "../Acueil/ChercheCoach";
import Newsletter from "../coach/Newsletter";
import Footer from "../coach/Footer";
import "./css/TrouverCoach.css"; 
import { IoMdPlay } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchInterface, addTemoignage } from "../../Redux/Slice/InterfaceSlice";
import { getImageUrl } from "../..";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AddTemoignages } from "../../Redux/Slice/TemoignegeSlice";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TrouverCoach = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [Texte, setTexte] = useState("");
  const [titre, setTitre] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { interfaceData } = useSelector((state) => state.interface);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const elem = document.querySelector('.section-hero');
      const { top } = elem.getBoundingClientRect();
      if (elem) {
        const backgroundPositionY = -(top + 25) * 0.6;
        elem.style.backgroundPosition = `50% ${backgroundPositionY}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchInterface());
  }, [dispatch]);

  const handleClick = (article) => {
    setSelectedEvent(article);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
    setIsSubmitted(false);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setTexte(data);
  };

  const handleTemClick = () => {
    navigate("/Temoignages");
  };

  const handleSendTemoignage = () => {
    const temoignage = {
      nom: titre,
      texte: Texte,
    };
    dispatch(AddTemoignages(temoignage)).then(() => {
      setTitre("");
      setTexte("");
      setIsSubmitted(true);
    });
  };

  const truncateText = (htmlText, maxLength) => {
    const cleanHtml = DOMPurify.sanitize(htmlText, {
      ALLOWED_TAGS: ["p", "b", "i", "u", "strong", "em", "br", "span"],
      ALLOWED_ATTR: ["style"],
    });

    const div = document.createElement("div");
    div.innerHTML = cleanHtml;

    let charCount = 0;

    const truncateNode = (node) => {
      if (charCount >= maxLength) return;

      if (node.nodeType === Node.TEXT_NODE) {
        const remainingChars = maxLength - charCount;
        if (node.textContent.length > remainingChars) {
          node.textContent = node.textContent.substring(0, remainingChars) + "...";
          charCount = maxLength;
        } else {
          charCount += node.textContent.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        node.childNodes.forEach(truncateNode);
      }
    };

    div.childNodes.forEach(truncateNode);

    return div.innerHTML;
  };

  return (
    <>
      <div>
        <div
          className="TrouverCoachPlatforme section-hero"
          data-stellar-background-ratio="0.5"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div>
            <h3 className="TrouveCoachTitre">Rencontrez votre coach!</h3>
          </div>
        </div>
        <ChercheCoach />

        {interfaceData && interfaceData.length > 0 && (
          <div className="Temaignage">
            <div className="Temaignage-container">
              <div className="PartageTEM">
                <div>
                  <h3 className="titreTemoignage">
                    {truncateText(interfaceData[0].titre)}
                  </h3>
                </div>
                <div id="styledtext">
                  <div dangerouslySetInnerHTML={{ __html: interfaceData[0].texte }} />
                </div>

                <div className="play-button">
                  <button className="PartageTem" onClick={() => handleClick(interfaceData[0])}>
                    Partagez votre Témoignage
                  </button>
                </div>
              </div>
              <div className="Temaignage-image">
                <h3 className="ParcouTem">Parcourez les témoignages !</h3>
                <div className="Tem-image">
                  <img
                    src={getImageUrl(interfaceData[0].image)}
                    alt="Image placeholder"
                    className="ImageTem"
                  />
                  <IoMdPlay className="icon-playTem" size={70} onClick={handleTemClick} />
                </div>
              </div>
            </div>
          </div>
        )}

        <Dialog open={showModal} onClose={closeModal} fullWidth maxWidth="md" sx={{
          '& .MuiDialog-paper': {
            height: '500px',
          }
        }}>
          <DialogContent>
            <div
              className="PlatformeEvn"
              data-stellar-background-ratio="0.5"
              style={{ backgroundImage: `url(${image})` }}
            >
              <h1 className="AjouterTemTitre">Ajoutez votre témoignage ici !</h1>
            </div>
            {isSubmitted ? (
              <h3 style={{ width: "100%", textAlign: "center", marginTop: "50px", fontWeight: "300", fontSize: '2.5rem' }}>
                Votre témoignage a bien été envoyé !
              </h3>
            ) : (
              <>
                <div style={{ width: "50%", display: 'block', margin: '0 auto' }}>
                  <input
                    type="text"
                    placeholder="Initiales nom et prénom (exemple A.S)"
                    value={titre}
                    onChange={(e) => setTitre(e.target.value)}
                    className="NomTem"
                  />
                  <CKEditor
                    editor={ClassicEditor}
                    data={Texte}
                    onChange={handleEditorChange}
                    config={{
                      toolbar: [
                        'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'
                      ]
                    }}
                  />
                </div>
                <button className="addTemoign" onClick={handleSendTemoignage}>
                  Envoyer
                </button>
              </>
            )}
          </DialogContent>
        </Dialog>

        {showModal && (
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: "fixed",
              right: "calc(54% - 500px + 8px)",
              top: "calc(50% - 250px - 40px)",
              color: "gray",
              fontWeight: '500',
              zIndex: 1000,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <Newsletter />
        <Footer />
      </div>
    </>
  );
};

export default TrouverCoach;
